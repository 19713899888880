<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          ref="searchWrapper"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.plate_number')">
                <el-autocomplete
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model.trim="plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Car_owner_name')">
                <el-input
                  v-model.trim="formInline.carOwner"
                  placeholder="请输入车主姓名"
                  size="12"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.phone_number')">
                <el-input v-model="formInline.mobile" />
              </el-form-item>
              <el-form-item :label="$t('searchModule.classification')">
                <el-select v-model="formInline.specialPlateType">
                  <el-option
                    v-for="item in specialPlateTypeList"
                    :key="item.code"
                    :label="item.desc"
                    :value="item.code"
                  />
                </el-select>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.Vehicle_status')"> -->
              <el-form-item :label="$t('searchModule.state')">
                <el-select v-model="formInline.carStatus">
                  <el-option label="全部" value="" />
                  <el-option label="启用" :value="1" />
                  <el-option label="停用" :value="0" />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Merchant_Name')">
                <el-select
                  v-model.trim="formInline.operationId"
                  filterable
                  size="15"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Road_name')">
                <my-component
                  ref="parkInput"
                  :areaIds="
                    formInline.streetId
                      ? formInline.streetId
                      : formInline.areaId
                  "
                  :operationId="formInline.operationId"
                  @valueChange="completeValue"
                  slaveRelations="0,2"
                ></my-component>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                v-if="$route.meta.authority.tabs.specialRecord.button.query"
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button icon="el-icon-refresh-right" @click="resetSearchForm"
                >{{ $t('button.reset') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button type="info" icon="el-icon-upload" plain @click="imporT"
                >批量{{ $t('button.import') }}</el-button
              >
            </div>
            <div class="col_right mbd4">
              <el-button
                v-if="$route.meta.authority.tabs.specialRecord.button.export"
                type="info"
                icon="el-icon-upload2"
                @click="exportFile"
                >{{ $t('button.export') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="70"
            align="center"
          ></el-table-column>
          <el-table-column
            :label="$t('list.plate_number')"
            align="center"
            prop="plateNumber"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('list.License_plate_color')"
            align="center"
            prop="plateColorName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('list.Car_owner_name')"
            align="center"
            prop="carOwner"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('list.phone_number')"
            align="center"
            prop="mobile"
            width="120"
          ></el-table-column>
          <el-table-column
            :label="$t('list.classification')"
            align="center"
            prop="specialPlateTypeName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('list.Merchant_Name')"
            align="center"
            prop="operationName"
            show-overflow-tooltip
          ></el-table-column>
          <!--出现重复-->
          <!-- <el-table-column
            :label="$t('searchModule.Merchant_Name')"
            align="center"
            prop="operationName"
          ></el-table-column> -->
          <el-table-column
            :label="$t('list.Road_name')"
            show-overflow-tooltip
            align="center"
            width="105"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.scope == 2">{{ scope.row.parkName }}</span>
              <span v-if="scope.row.scope == 1">{{
                $t("list.All_roads")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('list.Vehicle_status')"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div v-if="scope.row.carStatus === 0">
                <span style="color: red">{{ $t("list.Stop_using") }}</span>
              </div>
              <div v-if="scope.row.carStatus === 1">
                {{ $t("list.Enable") }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('list.update_time')"
            width="170"
            align="center"
            prop="updatedTime"
          ></el-table-column>
          <el-table-column
            :label="$t('list.operation')"
            align="center"
            width="80"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" style="padding: 0" size="small"
                  >操作<i class="el-icon-arrow-down" style="margin-left: 4px"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-if="$route.meta.authority.tabs.specialRecord.button.view"
                    command="a"
                    >查看</el-dropdown-item
                  >
                  <!-- <el-dropdown-item
                    command="b"
                    v-if="
                      $route.meta.authority.tabs.specialRecord.button.enable &&
                      scope.row.carStatus === 0
                    "
                    >启用</el-dropdown-item
                  > -->
                  <el-dropdown-item command="c" v-if="scope.row.carStatus === 1"
                    >停用</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="
                      $route.meta.authority.tabs.specialRecord.button.update
                    "
                    command="d"
                    >修改</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <el-dialog
        class="dialogHead"
        title="批量导入"
        :visible.sync="dialogVisibleupload"
        width="600px"
      >
        <div><span style="color: red">*</span>导入文件</div>
        <div style="margin-left: 100px">
          <div>
            <el-upload
              class="upload-demo"
              multiple
              drag
              ref="upload"
              :headers="header"
              :on-success="uploadSuccess"
              :limit="1"
              :on-exceed="handleExceed"
              :data="upData"
              action="/acb/2.0/specialPlateApply/import"
              :on-change="picChange"
              :file-list="fileList"
              :auto-upload="false"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">点击上传文件或拖拽上传文件</div>
              <!-- <el-input
          v-model="fileName"
          style="width: 200px; float: left; margin-right: 5px"
        ></el-input> -->
              <!-- <el-button slot="trigger" @click="uploadClick" type="primary"
          >浏览...</el-button
        > -->
            </el-upload>
          </div>
          <div>
            <el-button type="text" @click="exportModel">下载导入模板</el-button>
          </div>
          <div class="desper">
            <div>(提示：批量导入一行是一条记录，请控制在100条以内)</div>
            <div>1.同一白名单可以适用于多个道路</div>
            <div>2.多个道路必须为同一个商户下的道路</div>
            <div>3.多个道路名称直接以“，”隔开</div>
            <div>4.车牌颜色包括蓝、黄、绿、黄绿、黑、白</div>
            <div>
              5.分类包括军车、警车、特殊群体、应急抢险车、消防车以及救护车
            </div>
            <div>6. 状态包括启用、停用</div>
          </div>
        </div>
        <div slot="footer" class="dialog-footer" style="text-align: center">
          <el-button @click="dialogVisibleupload = false">取 消</el-button>
          <el-button type="primary" @click="submitUpload">{{ $t('button.import') }}</el-button>
        </div>
      </el-dialog>
    </div>
    <record-edit
      ref="recordEdit"
      :formData="formData"
      :dialogFormVisible="dialogFormVisible"
      @dialogFormClose="dialogFormClose"
      :tenantList="tenantList"
      :plateColorList="plateColorList"
      :specialPlateTypeList="specialPlateTypeList"
      @commitConfirm="commitConfirm"
    />
    <record-detail
      ref="recordDetail"
      :formData="formData"
      :dialogCheckVisible="dialogCheckVisible"
      :systemLogData="systemLogData"
      @dialogCheckClose="dialogCheckClose"
      :systemPageNum="systemPageNum"
      :systemPageSize="systemPageSize"
      :systemTotal="systemTotal"
      @handleSystemCurrentChange="handleSystemCurrentChange"
    />
  </div>
</template>

<script>
import RecordEdit from "./edit";
import RecordDetail from "./detail";
import { exportExcelNew } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";
export default {
  name: "specialRecord",
  components: {
    RecordEdit,
    RecordDetail,
    myComponent,
  },
  data() {
    return {
      upData: {
        "Session-Id": sessionStorage.token,
      },
      plateNumber: "",
      dialogVisibleupload: false,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      fileList: [],
      header: {
        "Session-Id": sessionStorage.token,
      },
      tableData: [],
      formInline: {
        carOwner: "",
        carId: "",
        mobile: "",
        specialPlateType: "",
        carStatus: "",
        operationId: "",
        parkId: "",
      },
      systemLogData: [],
      dialogFormVisible: false,
      dialogCheckVisible: false,
      formData: {},
      specialPlateTypeList: [],
      tenantList: [],
      plateColorList: [],
      systemPageNum: 1,
      systemPageSize: 5,
      systemTotal: 0,
      specialPlateId: "",
      specialPlateApplyId: "",
    };
  },
  watch: {
    plateNumber(newVal) {
      if (!newVal) {
        this.formInline.carId = "";
      }
    },
  },
  created() {
    console.log("com", this.$route.meta.authority);
  },
  mounted() {
    this.getTenantList();
    this.getPlateColorList();
    this.getSpecialPlateTypeList();
    this.searchData();
  },
  methods: {
    // 导入
    imporT() {
      this.dialogVisibleupload = true;
      this.picFlag = false;
      this.fileName = "";
      // this.$refs.upload.clearFiles();
    },
    // 下载模板
    exportModel() {
      let opt = {};
      opt = {
        scope: 1,
      };
      // exportExcelNew("/acb/2.0/bar/downloadExcelTemplate", opt);
      exportExcelNew("/acb/2.0/specialPlateApply/downloadExcelTemplate", opt);
    },
    submitUpload() {
      if (this.picFlag) {
        this.$refs.upload.submit();
        this.loading1 = true;
      } else {
        this.$alert("请选择需要上传的文件", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      }
    },
    picChange(file, row) {
      if (file.name && !file.response) {
        this.fileName = file.name;
        this.picFlag = true;
      } else {
        this.$refs.upload.clearFiles();
        this.fileName = "";
        this.picFlag = false;
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(`只能上传一个文件`);
    },
    // 文件上传成功
    uploadSuccess(res) {
      if (res.state == 0) {
        this.$message({
          type: "info",
          message: "文件上传成功",
        });
        this.dialogVisibleupload = false;
        this.pageNum = 1;
        this.searchData();
        this.loading1 = false;
      } else {
        this.$alert(res.desc + " 请重新选择文件！！！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        this.loading1 = false;
      }
    },
    handleSelect(item) {
      this.formInline.carId = item.carId;
      this.pageNum = 1;
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    querySearchAsync(queryString, cb) {
      this.formInline.carId = "";
      queryString = queryString.replace(/\s/g, "");
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      this.$axios
        .get("/acb/2.0/parkRecord/plateNumber/list", {
          data: {
            part: queryString.toUpperCase(),
            size: 100,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        });
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 获取车牌颜色列表
    getPlateColorList() {
      const opt = {
        url: "/acb/2.0/specialPlateApply/plateColor/list",
        method: "get",
        success: (res) => {
          this.plateColorList = res.value;
        },
      };
      this.$request(opt);
    },
    // 获取白名单类型列表
    getSpecialPlateTypeList() {
      const opt = {
        url: "/acb/2.0/specialPlateApply/specialPlateType/list",
        method: "get",
        success: (res) => {
          this.specialPlateTypeList = res.value;
        },
      };
      this.$request(opt);
    },
    // 分页
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 搜索
    searchData() {
      this.loading = true;
      const opt = {
        url: "/acb/2.0/specialPlateApply/queryList",
        method: "get",
        data: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          plateType: 0,
          ...this.formInline,
        },
        success: (res) => {
          this.loading = false;
          this.tableData = res.value.list;
          this.total = parseInt(res.value.total);
        },
        fail: (err) => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    // 导出
    exportFile() {
      const postData = Object.assign({ plateType: 0 }, this.formInline);
      exportExcelNew("/acb/2.0/specialPlateApply/export", postData);
    },
    // 清空
    resetSearchForm() {
      this.formInline = {
        carOwner: "",
        carId: "",
        mobile: "",
        specialPlateType: "",
        carStatus: "",
        operationId: "",
      };
      this.$refs.parkInput.setValue();
      this.plateNumber = "";
      this.searchData();
    },
    // 关闭编辑框
    dialogFormClose() {
      this.dialogFormVisible = false;
    },
    // 确认修改
    commitConfirm(formInline) {
      let {
        operationId,
        plateColor,
        plateNumber,
        carOwner,
        mobile,
        specialPlateType,
        carStatus,
        image,
        specialPlateApplyId,
        specialPlateId,
        resourcesId,
        fileList,
        parkIds,
        scope,
      } = formInline;
      let carColor = formInline.plateColor;
      let postData = {
        operationId,
        carColor,
        plateNumber,
        carOwner,
        mobile,
        specialPlateType,
        carStatus,
        plateType: 0,
        sourceType: 2,
        specialPlateId,
        parkIds,
        scope,
      };
      // if (!image) {
      postData.resourcesId = resourcesId;
      // }

      const opt = {
        // url: "/acb/2.0/specialPlateApply/create",
        url: "/acb/2.0/specialplate/update",
        method: "post",
        data: postData,
        success: (res) => {
          this.searchData();
          this.dialogFormVisible = false;
          let commonKey = res.value ? res.value.resourcesId : "";
          if (!image || image.length == 0) {
            this.$alert("操作成功");
            this.$refs.recordEdit.resetFields();
            this.searchData();
            return;
          }

          const uploadImg = [];
          fileList.forEach((item, index) => {
            if (item.file) {
              uploadImg.push(
                this.uploadPicture(
                  commonKey,
                  item.file,
                  item.oldIndex || index + 1
                )
              );
            }
          });
          Promise.all(uploadImg)
            .then(() => {
              this.$alert("操作成功");
              this.$refs.recordEdit.resetFields();
              this.searchData();
            })
            .catch((err) => {
              this.$alert("操作失败");
            });
        },
        fail: (err) => {
          this.dialogFormVisible = false;
        },
      };
      this.$request(opt);
    },
    // 上传图片
    uploadPicture(commonKey, img, index) {
      return new Promise((resolve, reject) => {
        const image = new FormData();
        image.append("image", img);
        const opt = {
          url:
            "/acb/2.0/specialPlateApply/upload?commonKey=" +
            commonKey +
            "&imageNo=" +
            index,
          method: "post",
          data: image,
          config: {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
          success: (res) => {
            resolve();
          },
        };
        this.$request(opt);
      });
    },
    // 关闭查看框
    dialogCheckClose() {
      this.dialogCheckVisible = false;
    },
    // 操作
    handleCommand(event, row) {
      const { specialPlateId } = row;
      switch (event) {
        case "a":
          this.getSpecialPlateDetail(specialPlateId);
          this.dialogCheckVisible = true;
          break;
        case "b":
          this.getSpecialPlateDetail(specialPlateId).then((res) => {
            this.upperOrLowerShelves(res, 3, 1, specialPlateId);
          });
          break;
        case "c":
          this.getSpecialPlateDetail(specialPlateId).then((res) => {
            this.upperOrLowerShelves(res, 4, 0, specialPlateId);
          });
          break;
        case "d":
          this.getSpecialPlateDetail(specialPlateId);
          this.dialogFormVisible = true;
          break;
        default:
          break;
      }
    },
    // 上下架
    upperOrLowerShelves(res, sourceType, carStatus, specialPlateId) {
      const status = carStatus === 1 ? "启用" : "停用";
      this.$confirm("将车辆设置为【" + status + "】状态，审核后生效!", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //   const postData = Object.assign({ sourceType }, res);
          const postData = {};
          postData.carStatus = carStatus;
          //   delete postData.updatedTime;
          postData.specialPlateId = specialPlateId;
          const opt = {
            // url: "/acb/2.0/specialPlateApply/create",
            url: "/acb/2.0/specialplate/stopSpecialPlate",
            method: "get",
            data: postData,
            success: (res) => {
              this.$alert("操作成功");
              this.searchData();
            },
          };
          this.$request(opt);
        })
        .catch((err) => {});
    },
    // 查询白名单记录详情
    getSpecialPlateDetail(specialPlateId) {
      return new Promise((resolve, reject) => {
        const opt = {
          url: "/acb/2.0/specialPlateApply/detail",
          method: "get",
          data: {
            specialPlateId,
          },
          success: (res) => {
            const { resourcesId } = res.value;
            this.specialPlateId = res.value.specialPlateId;
            this.specialPlateApplyId = res.value.specialPlateApplyId;
            this.getSystemLogData(
              this.specialPlateId,
              this.specialPlateApplyId
            );
            this.formData = res.value;
            resolve(this.formData);
            if (resourcesId) {
              // 根据id查询图片
              const option = {
                url: "/acb/2.0/specialPlateApply/getImage",
                method: "get",
                data: {
                  commonKey: resourcesId,
                },
                success: (response) => {
                  const value = JSON.parse(JSON.stringify(response.value));
                  const newData = Object.assign({}, res.value);
                  newData.fileList = [{ url: value[1] }, { url: value[2] }];
                  this.formData = newData;
                  resolve(newData);
                },
              };
              this.$request(option);
            }
          },
        };
        this.$request(opt);
      });
    },
    // 获取系统日志信息
    getSystemLogData(specialPlateId, specialPlateApplyId) {
      const opt = {
        url: "/acb/2.0/specialPlateApply/logList",
        method: "get",
        data: {
          pageNum: this.systemPageNum,
          pageSize: this.systemPageSize,
          specialPlateId,
          specialPlateApplyId,
        },
        success: (res) => {
          this.systemLogData = res.value.list;
          this.systemTotal = parseInt(res.value.total);
        },
      };
      this.$request(opt);
    },
    // 分页
    handleSystemCurrentChange(val) {
      this.systemPageNum = val;
      this.getSystemLogData(this.specialPlateId, this.specialPlateApplyId);
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content {
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    // margin-bottom: 20px;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 18px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.upload-demo {
  display: inline-block;
}

.desper div {
  margin-bottom: 5px;
}
</style>
