<template>
  <div>
    <el-dialog
      title="修改白名单"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="beforeClose"
    >
      <el-form ref="dialogForm" :model="formInline" :rules="rules" label-width="120px">
        <el-form-item :label="$t('searchModule.Merchant_affiliation')" prop="operationId">
          <el-select v-model.trim="formInline.operationId" filterable disabled size="15">
            <el-option
              :label="value.operationName"
              :value="value.operationId"
              :key="value.operationId"
              v-for="value in tenantList"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="适用范围" prop="scope">
          <el-select v-model="formInline.scope" disabled placeholder="请选择">
            <el-option label="全部车场" :value="1"></el-option>
            <el-option label="部分车场" :value="2"></el-option>
          </el-select>
          <span class="inputTip">*仅限商户下车场</span>
        </el-form-item>
        <el-form-item prop="parkIds" v-if="formInline.scope == 2">
          <el-transfer
            target-order="push"
            :titles="['未选择', '已选择']"
            filterable
            :props="{
              key: 'parkId',
              label: 'parkName',
            }"
            filter-placeholder="请输入车场名称"
            v-model="selected"
            :data="parkList"
          >
          </el-transfer>
        </el-form-item>
        <el-form-item :label="$t('searchModule.License_plate_color')" prop="plateColor">
          <el-select v-model="formInline.plateColor" disabled>
            <el-option
              v-for="item in plateColorList"
              :key="item.code"
              :label="item.desc"
              :value="item.code"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
          <el-input v-model="formInline.plateNumber" maxlength="10" disabled style="width: 220px" />
        </el-form-item>
        <el-form-item :label="$t('searchModule.Car_owner_name')" prop="carOwner">
          <el-input v-model="formInline.carOwner" maxlength="10" style="width: 220px" />
        </el-form-item>
        <el-form-item :label="$t('searchModule.phone_number')" prop="mobile">
          <el-input v-model="formInline.mobile" maxlength="11" style="width: 220px" />
        </el-form-item>
        <el-form-item :label="$t('searchModule.classification')" prop="specialPlateType">
          <el-select v-model="formInline.specialPlateType">
            <el-option
              v-for="item in specialPlateTypeList"
              :key="item.code"
              :label="item.desc"
              :value="item.code"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="车牌状态" prop="carStatus">
          <el-select v-model="formInline.carStatus">
            <el-option label="启用" :value="1" />
            <el-option label="停用" :value="0" />
          </el-select>
        </el-form-item>
        <el-form-item label="图片" prop="fileList" v-if="formInline.fileList">
          <el-upload
            action
            list-type="picture-card"
            :on-remove="handleRemove"
            :disabled="true"
            :http-request="handleFiles"
            :file-list="formInline.fileList"
            :class="{ hide: hideUploadEdit }"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormClose">取 消</el-button>
        <el-button type="primary" @click="commitConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      parkList: [],
      selected: [],
      formInline: {
        operationId: "",
        plateColor: "",
        plateNumber: "",
        carOwner: "",
        mobile: "",
        specialPlateType: "",
        carStatus: "",
        fileList: [],
        image: [],
        parkIds: "",
        scope: "",
      },
      rules: {
        operationId: [{ required: true, message: "请选择所属商户", trigger: "change" }],
        plateColor: [{ required: true, message: "请选择车牌颜色", trigger: "change" }],
        plateNumber: [
          { required: true, message: "请输入车牌号", trigger: "blur" },
          { min: 3, max: 11, message: "长度在 3 到 11 个字符", trigger: "blur" },
        ],
        carOwner: [{ required: true, message: "请输入车主姓名", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
            message: "请输入正确手机号",
            trigger: "blur",
          },
        ],
        specialPlateType: [{ required: true, message: "请选择分类", trigger: "change" }],
        carStatus: [{ required: true, message: "请选择车牌状态", trigger: "change" }],
        fileList: [{ required: true, message: "请选择图片", trigger: "change" }],
        scope: [
          {
            required: true,
            message: "请选择适用范围",
            trigger: "blur",
          },
        ],
      },
      hideUploadEdit: false,
      imgUploadIndex: [],
    };
  },
  props: {
    dialogFormVisible: {
      default: function () {
        return false;
      },
    },
    formData: {
      default: function () {
        return {};
      },
    },
    tenantList: {
      default: function () {
        return {};
      },
    },
    plateColorList: {
      default: function () {
        return {};
      },
    },
    specialPlateTypeList: {
      default: function () {
        return {};
      },
    },
  },
  watch: {
    formData() {
      this.formInline = this.formData;
      let add = [];
      let parkNames = [];
      if (this.formData.parks && this.formData.parks.length > 0) {
        this.formData.parks.forEach((element) => {
          add.push(element.parkId);
          parkNames.push(element.parkName);
        });
      }
      if (this.formInline.operationId) {
        this.queryParkList();
      }
      this.parkNameList = parkNames;
      this.selected = add;
      if (!this.formInline.image) {
        this.formInline.image = [];
      }
      if (this.formData.resourcesId) {
        this.imgUploadIndex = [];
        this.hideUploadEdit = this.formInline.fileList && this.formInline.fileList.length >= 2;
      }
    },
  },
  methods: {
    // 查询车场列表
    queryParkList(val) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/parkNameList", {
          data: {
            dataSource: 1,
            slaveRelations: "0,2",
            operationId: this.formInline.operationId,
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc);
            return;
          }
          this.parkList = res.value;
        });
    },
    // 确认提交
    commitConfirm() {
      this.formInline.parkIds = this.selected.join(",");
      if (this.formInline.scope == 2 && this.formInline.parkIds == "") {
        this.$alert("车场不能为空");
        return;
      }
      this.$refs["dialogForm"].validate((valid) => {
        if (valid) {
          //   if (this.formInline.fileList.length < 2) {
          //     this.$alert('请将图片上传完整')
          //     return
          //   }
          this.$emit("commitConfirm", this.formInline);
        }
      });
    },
    // 关闭弹窗
    dialogFormClose() {
      this.resetFields();
      this.$emit("dialogFormClose");
    },
    // 关闭弹窗
    beforeClose() {
      this.dialogFormClose();
    },
    // 删除图片
    handleRemove(file, fileList) {
      for (let i = 0; i < this.formInline.fileList.length; i++) {
        const item = this.formInline.fileList[i];
        if (item.uid == file.uid) {
          this.imgUploadIndex.push(i + 1);
          break;
        }
      }
      // this.formInline.fileList = fileList
      const images = [];
      this.formInline.fileList = fileList;
      fileList.forEach((ele) => {
        if (ele.file) {
          images.push(ele.file);
        }
      });
      this.formInline.image = images;
      // this.formInline.image = []
      this.hideUploadEdit = this.formInline.fileList.length >= 2;
    },
    // 选中图片
    handleFiles(file) {
      const fileItem = URL.createObjectURL(file.file);
      let fileListItem = { url: fileItem, file: file.file };
      if (this.imgUploadIndex.length == 1) {
        fileListItem.oldIndex = this.imgUploadIndex[0];
      } else if (this.imgUploadIndex.length == 2) {
        this.formInline.fileList.forEach((item) => {
          item.oldIndex = "";
        });
      }
      this.formInline.fileList.push(fileListItem);
      this.formInline.image.push(file.file);
      this.hideUploadEdit = this.formInline.fileList.length >= 2;
    },
    // 表单恢复默认值
    resetFields() {
      this.$refs["dialogForm"].resetFields();
      this.formInline.image = [];
      this.hideUploadEdit = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
>>>.hide
  .el-upload--picture-card
    display none
</style>
