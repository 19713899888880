<template>
  <div>
    <el-dialog
      title="查看白名单"
      :visible.sync="dialogCheckVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="beforeClose"
    >
      <div class="title">车辆信息</div>
      <el-form :model="formInline" label-width="80px">
        <el-row>
          <el-col :span="10">
            <el-form-item :label="$t('searchModule.plate_number')">
              {{ formInline.plateNumber }}
            </el-form-item>
            <el-form-item :label="$t('searchModule.Car_owner_name')">
              {{ formInline.carOwner }}
            </el-form-item>
            <el-form-item :label="$t('searchModule.classification')">
              {{ formInline.specialPlateTypeName }}
            </el-form-item>
            <el-form-item :label="$t('searchModule.Merchant_affiliation')">
              {{ formInline.operationName }}
            </el-form-item>
            <el-form-item label="适用范围">
              {{ formInline.scope == 1 ? "全部车场" : formInline.parkName }}
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item :label="$t('searchModule.License_plate_color')">
              {{ formInline.plateColorName }}
            </el-form-item>
            <el-form-item :label="$t('searchModule.phone_number')">
              {{ formInline.mobile }}
            </el-form-item>
            <el-form-item label="车牌状态">
              {{ formInline.carStatus | carStatusFilter }}
            </el-form-item>
            <el-form-item label="图片" v-if="formInline.fileList">
              <img
                v-if="formInline.fileList[0]"
                :src="formInline.fileList[0].url"
                class="images"
                @click="checkPicture(formInline.fileList[0].url)"
              />
              <img
                v-if="formInline.fileList[1]"
                :src="formInline.fileList[1].url"
                class="images"
                @click="checkPicture(formInline.fileList[1].url)"
              />
            </el-form-item>
            <el-dialog :visible.sync="dialogVisible" :modal="false">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </el-col>
        </el-row>
        <div style="text-align: center">
          <el-button type="primary" @click="dialogFormClose">关闭</el-button>
        </div>
      </el-form>
      <el-divider />
      <div class="title">系统日志</div>
      <el-table :data="systemLogData" border>
        <el-table-column
          :label="$t('list.index')"
          type="index"
          width="80"
          align="center"
        />
        <el-table-column
          :label="$t('list.Operator')"
          align="center"
          prop="operatorName"
        />
        <el-table-column
          :label="$t('list.peration_date')"
          align="center"
          prop="createdTime"
        />
        <el-table-column
          :label="$t('list.Operation_content')"
          align="center"
          prop="operationTypeName"
        />
        <el-table-column
          :label="$t('list.Review_comments')"
          align="center"
          prop="remark"
        />
      </el-table>
      <div class="pagerWrapper">
        <div class="block" style="text-align: center">
          <el-pagination
            @current-change="handleSystemCurrentChange"
            :current-page="systemPageNum"
            :page-size="systemPageSize"
            layout="total, prev, pager, next, jumper"
            :total="systemTotal"
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formInline: {
        name: "",
        color: "",
        plateNumber: "",
        carOwner: "",
        mobile: "",
        category: "",
        status: "",
        fileList: [],
      },
      dialogVisible: false,
      dialogImageUrl: "",
    };
  },
  filters: {
    carStatusFilter(val) {
      if (val === 0) {
        return "停用";
      } else if (val === 1) {
        return "正常";
      }
    },
  },
  props: {
    dialogCheckVisible: {
      default: function () {
        return false;
      },
    },
    formData: {
      default: function () {
        return {};
      },
    },
    systemLogData: {
      default: function () {
        return [];
      },
    },
    systemPageNum: {
      default: function () {
        return [];
      },
    },
    systemPageSize: {
      default: function () {
        return [];
      },
    },
    systemTotal: {
      default: function () {
        return [];
      },
    },
  },
  watch: {
    formData() {
      this.formInline = this.formData;
    },
  },
  methods: {
    // 关闭弹窗
    dialogFormClose() {
      this.$emit("dialogCheckClose");
    },
    // 查看大图
    checkPicture(dialogImageUrl) {
      this.dialogImageUrl = dialogImageUrl;
      this.dialogVisible = true;
    },
    // 系统日志分页
    handleSystemCurrentChange(val) {
      this.$emit("handleSystemCurrentChange", val);
    },
    // 关闭弹窗
    beforeClose() {
      this.dialogFormClose();
    },
  },
};
</script>

<style lang="stylus" scoped>
.title {
  font-weight: bold;
  font-size: 16px;
  border-left: 6px solid #0f6eff;
  padding-left: 8px;
  height: 20px;
  line-height: 20px;
  margin-bottom: 20px;
}

.images {
  width: 120px;
  margin-right: 20px;
}

>>>.el-form-item {
  margin-bottom: none;
}
</style>
