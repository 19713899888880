<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <el-tabs
        class="bgFFF"
        style="border-radius: 12px"
        v-model="activeName"
        @tab-click="handleTab"
      >
        <el-tab-pane label="白名单记录" name="1">
          <specialRecord ref="specialRecord" v-if="authority.tabs['specialRecord']"></specialRecord>
        </el-tab-pane>
        <el-tab-pane label="白名单申请" name="2">
          <specialApply ref="specialApply" v-if="authority.tabs['specialApply']"></specialApply>
        </el-tab-pane>
        <!-- <el-tab-pane v-if='authority.tabs["payRepeat2"]' -->
        <el-tab-pane label="白名单审核" name="3">
          <specialAudit ref="specialAudit" v-if="authority.tabs['specialAudit']"></specialAudit>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import specialApply from "./specialApply/index.vue";
import specialAudit from "./specialAudit/index.vue";
import specialRecord from "./specialRecord/index.vue";

export default {
  name: "payRepeat",
  components: {
    specialAudit,
    specialRecord,
    specialApply,
  },
  data() {
    return {
      authority: this.$route.meta.authority,
      activeName: "1",
    };
  },
  methods: {
    handleTab(vm) {
      //   vm.name == 1 ? this.$refs.payRepeatList.searchData() : this.$refs.payRepeatRefund.searchData()
    },
  },
  created() {
    console.log("白名单权限", this.$route.meta.authority);
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
>>>.el-tabs__header
    padding-left 0px
    margin: 0
    .el-tabs__nav-wrap {
      height: 40px
      .el-tabs__nav-scroll {
      }
      .el-tabs__nav {
        height 40px
        .el-tabs__item {
          padding: 9px 16px
          height 100%
        }
      }
    }
.breadcrumb
  height 35px
.content
  overflow hidden
  .searchWrapper
    overflow hidden
    background #EFF2F7
    padding 22px 22px 0
    margin-bottom 20px
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    float right
    .export
      font-size 12px
      .iconfont
        margin-right 0
  .btnWrapper
    text-align left
    margin-top 28px
    float left
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
</style>
